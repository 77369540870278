.heading {
    text-align: center;
    color: #063c2a;
    font-size: 3rem;
    margin-top: 1rem;

}

.g-form{
    height: 50vh;
}


.fixed_headers {
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    font-style: normal;
    font-size: 1.2rem;
    animation: appear 0.7s ease;

    width: fit-content;
    
    table-layout: fixed;
    border-collapse: collapse;

    th,
    td {
        padding: 5px 20px;
        text-align: center;
    }

    /* tbody {
        width: fit-content;
    } */

    td:nth-child(1),
    th:nth-child(1) {
        min-width: 250px;
    }

    td:nth-child(2),
    th:nth-child(2) {
        min-width: 200px;
    }

    td:nth-child(3),
    th:nth-child(3) {
        min-width: 275px;
    }



    thead {
        padding: 10vh;
        background-color: #e04c4c;
        color: #FDFDFD;

        tr {

            display: block;
            position: relative;
        }
    }


    tbody {
        display: block;

        width: 100%;
        height: fit-content;


        tr:nth-child(even) {
            background-color: #f1ecec;
        }
        tr:nth-child(odd) {
            background-color: #fff;
        }
    }
}

.memberType {
    background-color: #e04c4c60;
    color: #fff;
}

.form{
    a{
        text-decoration: none;
        font-weight: bold;
        width: fit-content;
        padding : 10px;
        color: #fff;
        background-color: #2dcce4;
        border-radius: 5px;
    };

    margin: 1rem 0;
    text-align: center;
}

.form a:hover{
    background-color: #2d88e4;
}


@keyframes appear {
    0%{
        transform: translateY(-20%);
        opacity : 0;
    }

    to {
        transform: translateY(0);
        opacity : 1;
    }
    
}


@media (max-width: 767px) {
    .fixed_headers{
        width:max-content;
        font-size:0.8rem;
        

        tbody {
            display: block;
    
            width: 100%;
            /* min-height: 25vh; */
           height: auto;}

            th,
            td {
                padding: 5px;
                text-align: center;
            }
            td:nth-child(1),
            th:nth-child(1) {
                min-width: 30vw;
            }
        
            td:nth-child(2),
            th:nth-child(2) {
                min-width: 30vw;
            }
        
            td:nth-child(3),
            th:nth-child(3) {
                min-width: 40vw;;
            }
    }
    .heading h1{
        font-size: 2rem;
    }



}


/* .old_ie_wrapper {
    height: 300px;
    width: 750px;
    overflow-x: hidden;
    overflow-y: auto;
    tbody { height: auto; }
  } */