* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}


header {
    text-align: center;
    margin-bottom: 60px;
}

h1 {
    font-size: 3rem;
    color: #34495e;
    margin-bottom: 15px;
}

header p {
    font-size: 1.2rem;
    color: #7f8c8d;
}
.Scard{
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.Scard:hover{
    transform: translateY(-10px);
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.12);
}

.committee {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 40px;
    padding: 0 50px;
}

.member {
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.08);
    padding: 40px;
    max-width: 300px;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    border-top: 5px solid #3498db;
}

.member:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.12);
}

h2 {
    font-size: 1.8rem;
    color: #2980b9;
    margin-bottom: 5px;
}

p {
    font-size: 1rem;
    color: #4d4d4d;
}

p.role {
    font-weight: bold;
    font-size: 1.1rem;
    color: #16a085;
}

.member::before {
    content: "";
    display: block;
    width: 50px;
    height: 5px;
    background-color: #16a085;
    margin: 0 auto 20px auto;
    border-radius: 3px;
}
