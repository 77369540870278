body {
    margin: 0;
    font-family: Arial, sans-serif;
    background-color: #f4f4f4;
    text-align: center;
  }
  
  .Header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
  }
  
  .Sub-header {
    padding: 20px;
    background-color: #6e8454;
    color: white;
    width: 100%;
  }
  
  .image-section {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 20px;
    max-width: 1200px;
  }
  
  .image-item {
    width: 300px;
    height: auto;
    margin: 20px;
    border-radius: 10px;
    transition: transform 0.3s ease;
  }
  
  .image-item:hover {
    transform: scale(1.05);
  }
  
  .link-section {
    margin-top: 40px;
  }
  
  .main-link {
    padding: 15px 30px;
    background-color: #61dafb;
    color: white;
    text-decoration: none;
    font-size: 1.5rem;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .main-link:hover {
    background-color: #21a1f1;
  }
  